* {
  outline: none;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.glass {
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  background:rgba(0, 0, 0, .8)
}

.slide-container {
  position: relative;
  overflow: hidden;
  background-color: white;
}

.slide-content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: white;
  transition: transform 0.3s ease;
}

.slide-action {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 100px;
  background-color: red;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: width 0.3s ease;
}

.slide-action span {
  display: none;
}

.slide-content.slide-left {
  transform: translateX(-100px);
}

.slide-content.slide-left + .slide-action {
  width: 100px;
}

.slide-content.slide-left + .slide-action span {
  display: inline-block;
}